import React from "react"
import NewsletterForm from "../components/NewsletterForm"
import Banner from "../sections/OldDesign/news/Banner"
import NewsHelmet from "../sections/OldDesign/news/NewsHelmet"
import NewsLatestUpdates from "../sections/OldDesign/news/NewsLatestUpdates"
import NewsMediaKit from "../sections/OldDesign/news/NewsMediaKit"
import NewsPressReleases from "../sections/OldDesign/news/NewsPressReleases"
import NewsVideos from "../sections/OldDesign/news/NewsVideos"
import BannerImg from "../assets/OldDesign/news/ZBF_news_banner_join_discord.jpg"
import { URLProvider } from "../utils/URLProvider"
import NewsAboutHorizen from "../sections/OldDesign/news/NewsAboutHorizen"
import NewsHeroCarousel from "../sections/OldDesign/news/NewsHeroCarousel"
import NewsTopBanner from "../sections/OldDesign/news/NewsTopBanner"
import NewsLayout from "../sections/OldDesign/news/NewsLayout"

const News = () => {
    return (
        <NewsLayout>
            <div className="bg-horizen-dark-blue">
                <NewsHelmet />
                <NewsTopBanner />
                <NewsHeroCarousel />
                <Banner
                    imgUrl={BannerImg}
                    href={URLProvider.URL_DISCORD}
                    alt="Horizen Discord"
                />
                <NewsPressReleases />
                <NewsLatestUpdates />
                <NewsVideos />
                <NewsMediaKit />
                <NewsAboutHorizen />
                <NewsletterForm />
            </div>
        </NewsLayout>
    )
}

export default News
