/* eslint-disable react/no-danger */
import React from "react"
import { useQuery } from "react-query"
import Link from "../../../components/OldDesign/Link"
import ContentContainer from "../../../templates/ContentContainer"
import ArrowImg from "../../../assets/OldDesign/news/arrow-right.svg"
import { WPPostT } from "./types"
import WPMiddleware from "./middlewares/WPMiddleware"
import Loading from "../../../components/Loading"

interface NewsLatestUpdatesProps {
    post: WPPostT
}

const NewsLatestUpdate: React.FC<NewsLatestUpdatesProps> = ({ post }) => {
    return (
        <a href={post.link} className="pb-[56.25%] relative group">
            <img
                src={post.image}
                alt={post.title}
                loading="lazy"
                className="absolute top-0 left-0 h-full w-full object-cover bg-old-design-dark-blue z-0"
            />

            <div className="bg-old-design-dark-blue h-12 w-full absolute bottom-0 left-0 z-[1] px-4 group-hover:h-full transition-all overflow-hidden">
                <div className="relative h-full">
                    <div className="absolute left-0 top-0 h-full font-proxima text-white text-base flex items-center opacity-100 group-hover:opacity-0">
                        {post.dateMMMMDDYYYY}
                    </div>
                    <div className="absolute left-0 top-0 h-full opacity-0 group-hover:opacity-100 p-4 flex flex-col justify-center">
                        <h5 className="font-proxima text-old-design-blue text-xl font-medium">
                            {post.categoriesWithNames?.[0]}
                        </h5>
                        <p
                            className="font-proxima text-xl font-medium mt-2 text-white decoration-old-design-blue decoration-3 underline-offset-4 hover:underline transition-colors overflow-hidden [display:-webkit-box] [-webkit-line-clamp:2] [-webkit-box-orient:vertical]"
                            dangerouslySetInnerHTML={{
                                __html: post.description,
                            }}
                        />
                        <p className="font-proxima text-base text-white mt-2">
                            {post.dateMMMMDDYYYY}
                        </p>

                        <img
                            className="w-28 mt-2"
                            src={ArrowImg}
                            alt="arrow pointing to the right"
                        />
                    </div>
                </div>
            </div>
        </a>
    )
}

const NewsLatestUpdates = () => {
    const { data, isSuccess } = useQuery(
        "news-latest-updates",
        WPMiddleware.geLatestUpdates
    )

    return (
        <section className="py-12 bg-white">
            <ContentContainer>
                <h2 className="font-proxima text-3xl font-bold text-old-design-dark-blue uppercase">
                    Latest Updates
                </h2>
                {isSuccess ? (
                    <>
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-7 mt-6">
                            {data.map((latestUpdate) => (
                                <NewsLatestUpdate
                                    key={latestUpdate.id}
                                    post={latestUpdate}
                                />
                            ))}
                        </div>
                        <div className="flex justify-end mt-6">
                            <Link href="/news/news-releases/">
                                See All Updates
                            </Link>
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </ContentContainer>
        </section>
    )
}

export default NewsLatestUpdates
