import React from "react"
import loadingGif from "../assets/loading.gif"

const Loading = () => {
    return (
        <div className="flex justify-center items-center p-6">
            <img
                className="h-[100px] w-[100px]"
                src={loadingGif}
                alt="loading"
            />
        </div>
    )
}

export default Loading
