import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import * as styles from "../../../../components/Header/Header.module.css"
import HorizenLogo from "../../../../assets/HorizenLogo"
import MobileNavigation from "./MobileNavigation"
import NavContainer from "../../../../templates/NavContainer"
import useDocumentScrollThrottled from "../../../../utils/useDocumentScrollThrottled"
import DesktopNavigation from "./DesktopNavigation"
import { MenuItemT } from "./MenuTypes"

export interface HeaderProps {
    leftMenuItems?: MenuItemT[]
    rightMenuItems?: MenuItemT[]
    logo?: React.ReactNode
    logoLink?: string
}

const Header: React.FC<HeaderProps> = ({
    leftMenuItems = [],
    rightMenuItems = [],
    logo = <HorizenLogo />,
    logoLink = "/",
}) => {
    const [shouldHideHeader, setShouldHideHeader] = useState(false)
    const [shouldShowShadow, setShouldShowShadow] = useState(false)

    const MINIMUM_SCROLL = 80
    const TIMEOUT_DELAY = 500

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useDocumentScrollThrottled((callbackData: any) => {
        const { previousScrollTop, currentScrollTop } = callbackData
        const isScrolledDown = previousScrollTop < currentScrollTop
        const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL

        setShouldShowShadow(currentScrollTop > 2)

        setTimeout(() => {
            setShouldHideHeader(isScrolledDown && isMinimumScrolled)
        }, TIMEOUT_DELAY)
    })

    const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false)
    useEffect(() => {
        if (shouldHideHeader) {
            setIsMobileMenuOpened(false)
        }
    }, [shouldHideHeader])

    const shadowStyle = shouldShowShadow ? `${styles.shadow} ` : ""
    const hiddenStyle = shouldHideHeader ? `${styles.hidden} ` : ""

    return (
        <header
            className={`${styles.header} ${hiddenStyle}${shadowStyle}bg-transparent text-white z-10 top-0 w-full fixed h-[108px] lg:h-[190px]`}
        >
            <NavContainer>
                <DesktopNavigation
                    leftMenuItems={leftMenuItems}
                    rightMenuItems={rightMenuItems}
                />

                <div className="lg:flex items-center lg:m-[-2.25rem] border-b lg:border-b-0">
                    <div className="flex-grow border-b mr-3 hidden lg:block" />
                    <div className="z-10 flex justify-between items-center">
                        <Link to={`${logoLink}`}>{logo}</Link>
                        <MobileNavigation
                            menuItems={[leftMenuItems, rightMenuItems].flat()}
                            isMobileMenuOpened={isMobileMenuOpened}
                            setIsMobileMenuOpened={setIsMobileMenuOpened}
                        />
                    </div>
                    <div className="flex-grow border-b ml-3 hidden lg:block" />
                </div>
            </NavContainer>
        </header>
    )
}

export default Header
