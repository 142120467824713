import React from "react"
import { Helmet } from "react-helmet"

const NewsHelmet = () => {
    return (
        <Helmet>
            <title>
                Horizen Newsroom - Latest news, Announcements, Videos, Press kit
            </title>
            <meta
                property="og:title"
                content="Horizen Newsroom - Latest news, Announcements, Videos, Press kit"
            />
            <meta
                name="twitter:title"
                content="Horizen Newsroom - Latest news, Announcements, Videos, Press kit"
            />
            <meta name="theme-color" content="#041742" />
            <meta
                name="description"
                content="Horizen Newsroom is the source for news about Horizen. It provides the latest stories and news regarding its products, people and businesses. It's also a media source for press and influencers."
            />
            <meta
                property="og:description"
                content="Horizen Newsroom is the source for news about Horizen. It provides the latest stories and news regarding its products, people and businesses. It's also a media source for press and influencers."
            />
            <meta
                name="twitter:description"
                content="Horizen Newsroom is the source for news about Horizen. It provides the latest stories and news regarding its products, people and businesses. It's also a media source for press and influencers."
            />
            <meta
                property="og:image"
                content="https://www.horizen.io/assets/images/meta/news.jpg"
            />
            <meta
                name="twitter:image"
                content="https://www.horizen.io/assets/images/meta/news.jpg"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="keywords" content="" />
            <link rel="canonical" href="https://www.horizen.io/news/" />
            <meta property="og:url" content="https://www.horizen.io/news/" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Horizen" />
            <meta name="twitter:site" content="@horizenglobal" />
            <meta name="twitter:creator" content="@horizenglobal" />
        </Helmet>
    )
}

export default NewsHelmet
