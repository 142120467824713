import React from "react"

interface ButtonProps {
    href?: string
    newTab?: boolean
    letterSpacing?: boolean
    onClick?: () => void
    width?: "fit" | "fill"
    download?: boolean
}

const Button: React.FC<ButtonProps> = ({
    children,
    href,
    newTab,
    onClick,
    letterSpacing,
    width = "fill",
    download = false,
}) => {
    const className = `block text-center bg-old-design-blue text-white transition-colors px-8 py-4 uppercase font-proxima text-base hover:bg-old-design-dark-blue active:bg-old-design-mid-blue 
        ${letterSpacing ? " tracking-[4px] " : ""} 
        ${width === "fill" ? " w-full " : ""} 
        ${width === "fit" ? " w-full sm:w-fit " : ""} 
        `
    return (
        <>
            {href ? (
                <a
                    href={href}
                    target={newTab ? "_blank" : "_self"}
                    rel="noreferrer"
                    className={className}
                    download={download}
                >
                    {children}
                </a>
            ) : (
                <button onClick={onClick} type="button" className={className}>
                    {children}
                </button>
            )}
        </>
    )
}

export default Button
