/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react"
import Link from "../../../components/OldDesign/Link"
import { WPPostT } from "./types"

interface PostProps {
    post: WPPostT
}

const Post: React.FC<PostProps> = ({ post }) => {
    return (
        <div className="flex flex-col lg:flex-row bg-old-design-gray border border-transparent group hover:border-old-design-blue hover:bg-white transition-colors relative cursor-pointer">
            <a
                href={post.link}
                className="absolute inset-0 z-0"
                rel="noopener noreferrer"
                target="_blank"
            />
            <img
                src={post.image}
                alt={post.title}
                className="bg-old-design-dark-blue object-fill w-full lg:min-h-[180px] xl:h-[160px] lg:w-[300px] lg:flex-shrink-0 aspect-video"
            />
            <div className="p-4">
                <h4
                    className="font-proxima text-lg font-bold text-horizen-dark-blue overflow-hidden [display:-webkit-box] [-webkit-line-clamp:2] [-webkit-box-orient:vertical]"
                    dangerouslySetInnerHTML={{ __html: post.title }}
                />
                <p className="mt-2 font-proxima text-base text-horizen-dark-blue">
                    <span
                        className="overflow-hidden [display:-webkit-box] [-webkit-line-clamp:2] [-webkit-box-orient:vertical] mb-2 [word-break:break-all]"
                        dangerouslySetInnerHTML={{ __html: post.description }}
                    />

                    <Link href={post.link} newTab>
                        Read more
                    </Link>
                </p>
            </div>
        </div>
    )
}

export default Post
