import React from "react"

import NewsroomLogoSVG from "../../../../assets/OldDesign/news/ZBF_newsroom-logo-on-dark.svg"

const NewsroomLogo = () => {
    return (
        <div className="h-[58px] w-full max-w-[280px] flex items-center justify-center">
            <img src={NewsroomLogoSVG} className="w-full" alt="Logo" />
        </div>
    )
}

export default NewsroomLogo
