import React from "react"
import ContentContainer from "../../../templates/ContentContainer"
import PressReleases from "./NewsPressReleases/PressReleases"

const NewsPressReleases = () => {
    return (
        <section className="py-12 bg-white">
            <ContentContainer>
                <PressReleases />
            </ContentContainer>
        </section>
    )
}

export default NewsPressReleases
