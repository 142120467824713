import React from "react"
import bgImg from "../../../assets/OldDesign/news/media-kit.png"
import Button from "../../../components/OldDesign/Button"
import ContentContainer from "../../../templates/ContentContainer"

const NewsMediaKit = () => {
    return (
        <section
            id="press-kit"
            className="bg-old-design-gray py-12 lg:py-0 lg:pb-16 lg:bg-white relative"
        >
            <img
                className="absolute z-0 h-[200px] object-cover hidden lg:block w-[100vw] 2xl:max-w-screen-xl 2xl:left-1/2 2xl:-translate-x-1/2"
                src={bgImg}
                alt="media kit"
            />
            <ContentContainer>
                <div className="relative inset-0 z-[1] grid grid-cols-1 lg:grid-cols-2 lg:h-[200px] items-center gap-y-6 my-auto 2xl:px-12">
                    <div>
                        <h2 className="font-proxima text-old-design-dark-blue text-3xl font-bold uppercase">
                            MEDIA KIT
                        </h2>
                        <p className="font-proxima text-base text-old-design-dark-blue mt-4">
                            The power of the Horizen brand relies on the
                            consistent use of the brand guidelines throughout
                            all communications regardless of medium.
                        </p>
                    </div>

                    <div className="flex justify-start lg:justify-end">
                        <Button width="fit" href="/media" newTab>
                            Download full media kit
                        </Button>
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default NewsMediaKit
