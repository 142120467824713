import React, { useLayoutEffect } from "react"
import useEmblaCarousel from "embla-carousel-react"
import Autoplay from "embla-carousel-autoplay"
import ContentContainer from "../../../templates/ContentContainer"
import { CoingeckoMiddleware } from "./middlewares"
import { MarketDataT } from "./types"

const autoplayPlugin = Autoplay({
    delay: 6000,
    breakpoints: {
        "(min-width: 1024px)": {
            active: false,
        },
    },
})

interface InfoItemProps {
    text1: string
    text2: string
    text3: string
}

const InfoItem: React.FC<InfoItemProps> = ({ text1, text2, text3 }) => {
    return (
        <div className="grow-0 shrink-0 justify-center basis-[100%] md:basis-[50%] lg:basis-[25%] text-center">
            <p className="font-proxima text-base text-white lg:max-w-[160px] xl:max-w-none mx-auto">
                {text1}{" "}
                <span className="font-bold text-old-design-green">{text2}</span>{" "}
                <b>{text3}</b>
            </p>
        </div>
    )
}

const NewsTopBanner = () => {
    const [zenData, setZenData] = React.useState<MarketDataT>()

    const [emblaRef] = useEmblaCarousel(
        {
            loop: true,
            align: "start",
            draggable: false,
        },
        [autoplayPlugin]
    )

    useLayoutEffect(() => {
        CoingeckoMiddleware.getZENMarketData().then((data) => {
            setZenData(data)
        })
    }, [])

    return (
        <div className="pt-14">
            <ContentContainer>
                <div className="pt-12 pb-8 overflow-hidden" ref={emblaRef}>
                    <div className="flex justify-between">
                        <InfoItem
                            text1="ZEN Current Price:"
                            text2={zenData?.price || "$0"}
                            text3="USD"
                        />
                        <InfoItem
                            text1="Volume (24h):"
                            text2={zenData?.totalVolume || "$0"}
                            text3="USD"
                        />
                        <InfoItem
                            text1="Total Supply:"
                            text2={zenData?.totalSupply || "0"}
                            text3="ZEN"
                        />
                        <InfoItem
                            text1="Circulating Supply:"
                            text2={zenData?.circulatingSupply || "0"}
                            text3="ZEN"
                        />
                    </div>
                </div>
            </ContentContainer>
        </div>
    )
}

export default NewsTopBanner
