import React from "react"

interface LinkProps {
    href?: string
    newTab?: boolean
    download?: boolean
    children: string
}

const Link: React.FC<LinkProps> = ({ href, newTab, children, download }) => {
    return (
        <a
            href={href}
            target={newTab ? "_blank" : "_self"}
            download={download}
            rel="noreferrer"
            className="font-proxima transition-colors no-underline text-old-design-blue hover:text-old-design-mid-blue hover:underline"
        >
            {children}
        </a>
    )
}

export default Link
