import React from "react"
import { useQuery } from "react-query"
import Loading from "../../../components/Loading"
import Link from "../../../components/OldDesign/Link"
import YoutubeVideo from "../../../components/OldDesign/YoutubeVideo"
import ContentContainer from "../../../templates/ContentContainer"
import { RSSMiddleware } from "./middlewares"

const NewsVideos = () => {
    const { data, isSuccess } = useQuery(
        "news-videos",
        RSSMiddleware.getYoutubeRSSs
    )

    return (
        <section className="pb-16 bg-white">
            <ContentContainer>
                <h2 className="font-proxima text-old-design-dark-blue text-3xl font-bold uppercase">
                    VIDEOS
                </h2>
                {isSuccess ? (
                    <>
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-7 mt-6">
                            {data.map((video) => (
                                <YoutubeVideo
                                    key={video.id}
                                    videoId={video.id}
                                />
                            ))}
                        </div>
                        <div className="flex justify-end mt-6">
                            <Link
                                newTab
                                href="https://www.youtube.com/channel/UCQ0v_lUnZHIKUQUXJzfgqOg"
                            >
                                See All Videos
                            </Link>
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </ContentContainer>
        </section>
    )
}

export default NewsVideos
