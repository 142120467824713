import React, { useEffect, useState } from "react"
import { Turn as Hamburger } from "hamburger-react"
import MobileHeaderNavItem from "../../../../components/Header/MobileHeaderNavItem"
import SubMenuItem from "../../../../components/Header/SubMenuItem"
import { MenuItemT } from "./MenuTypes"

type MobileNavigationProps = {
    isMobileMenuOpened: boolean
    setIsMobileMenuOpened: React.Dispatch<React.SetStateAction<boolean>>
    menuItems: MenuItemT[]
}

const MobileNavigation = ({
    isMobileMenuOpened: isMenuOpened,
    setIsMobileMenuOpened: setIsMenuOpened,
    menuItems,
}: MobileNavigationProps) => {
    const [activeMenu, setActiveMenu] = useState(-1)

    useEffect(() => {
        if (isMenuOpened === false) {
            setActiveMenu(-1)
        }
    }, [isMenuOpened])

    const handleMenu = (index: number) => {
        if (activeMenu === index) {
            setActiveMenu(-1)
        } else {
            setActiveMenu(index)
        }
    }

    return (
        <div className="lg:hidden ml-6">
            <Hamburger
                hideOutline
                toggled={isMenuOpened}
                toggle={setIsMenuOpened}
                onToggle={(toggled) => {
                    if (toggled) {
                        setIsMenuOpened(true)
                    } else {
                        setIsMenuOpened(false)
                    }
                }}
                color={isMenuOpened ? "#0E9DE5" : "#FFFFFF"}
            />
            <div
                className={`fixed left-6 sm:left-16 top-[6.65rem] w-[calc(100vw-3rem)] sm:w-[calc(100vw-8rem)] border bg-horizen-hp-bg overflow-y-scroll max-h-[calc(100vh-150px)] md:h-auto transition-all duration-300
                ${isMenuOpened ? "opacity-100" : "opacity-0 invisible"}
                `}
            >
                {menuItems.map((item, index) => (
                    <MobileHeaderNavItem
                        key={`mobile-menu-item-${item.label}`}
                        label={item.label}
                        href={item.href}
                        openNewTab={item.openNewTab}
                        active={activeMenu === index}
                        handler={() => handleMenu(index)}
                    >
                        {item.subMenuItems?.map((subItem) => (
                            <SubMenuItem
                                key={`mobile-submenu-item-${subItem.link}`}
                                title={subItem.title}
                                desc={subItem.desc}
                                link={subItem.link}
                                openNewTab={subItem.openNewTab}
                            />
                        ))}
                    </MobileHeaderNavItem>
                ))}
            </div>
        </div>
    )
}

export default MobileNavigation
