/* eslint-disable react/no-array-index-key */
import React from "react"
import SubMenuItem from "../../../../components/Header/SubMenuItem"
import HeaderNavItem from "../../../../components/Header/HeaderNavItem"
import { MenuItemT } from "./MenuTypes"

interface DesktopNavigationProps {
    leftMenuItems: MenuItemT[]
    rightMenuItems: MenuItemT[]
}

const DesktopNavigation: React.FC<DesktopNavigationProps> = ({
    leftMenuItems,
    rightMenuItems,
}) => {
    return (
        <div className="hidden justify-between px-4 lg:flex relative">
            {[leftMenuItems, rightMenuItems].map((menuItems, index) => (
                <div
                    key={`menu-align-${index}`}
                    className={index === 0 ? "space-x-10 flex" : ""}
                >
                    {menuItems?.map((item) => (
                        <HeaderNavItem
                            key={`menu-item-${item.label}`}
                            label={item.label}
                            href={item.href}
                            openNewTab={item.openNewTab}
                        >
                            <div
                                className={`${
                                    item.subMenuItems ? "grid" : "hidden"
                                } grid-cols-2 gap-[1px] border bg-white`}
                            >
                                {item.subMenuItems?.map((subItem) => (
                                    <div
                                        key={`submenu-item-${subItem.link}`}
                                        className={
                                            subItem.isBig
                                                ? "last-of-type:col-span-2"
                                                : ""
                                        }
                                    >
                                        <SubMenuItem
                                            title={subItem.title}
                                            desc={subItem.desc}
                                            link={subItem.link}
                                            openNewTab={subItem.openNewTab}
                                        />
                                    </div>
                                ))}
                            </div>
                        </HeaderNavItem>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default DesktopNavigation
