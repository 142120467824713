import React from "react"
import bgImg from "../../../assets/OldDesign/news/about-horizen.jpg"
import Button from "../../../components/OldDesign/Button"
import ContentContainer from "../../../templates/ContentContainer"

const NewsAboutHorizen = () => {
    return (
        <section className="bg-white pb-16">
            <div className="relative">
                <img
                    className="absolute z-0 h-full w-full object-cover 2xl:max-w-screen-xl 2xl:left-1/2 2xl:-translate-x-1/2"
                    src={bgImg}
                    alt="about"
                />
                <ContentContainer>
                    <div className="py-12 relative z-[1] 2xl:px-12">
                        <h2 className="font-proxima text-white text-3xl font-bold uppercase">
                            ABOUT HORIZEN
                        </h2>
                        <p className="font-proxima text-white text-xl mt-6 mb-6">
                            Horizen is the most secure interoperable blockchain
                            ecosystem supported by the largest and most
                            decentralized multi-tiered node infrastructure in
                            the industry.
                            <br />
                            <br />
                            Horizen focuses on scalable data privacy and enables
                            businesses and developers to custom build their own
                            public or permissioned blockchains using its unique
                            sidechain technology, Zendoo.
                            <br />
                            <br />
                            Horizen has a sustainable governance model, a
                            dedicated core team maintaining the network and a
                            passionate community supporting its mission.
                        </p>
                        <Button width="fit" href="/what-is-horizen/">
                            READ MORE
                        </Button>
                    </div>
                </ContentContainer>
            </div>
        </section>
    )
}

export default NewsAboutHorizen
