import React from "react"
import { HeaderProps } from "./Header"
import NewsroomLogo from "./NewsroomLogo"

const NewsHeaderProps: HeaderProps = {
    leftMenuItems: [
        {
            label: "Press",
            subMenuItems: [
                {
                    title: "Press Releases",
                    link: "/news/#press-releases",
                },
                {
                    title: "Contacts",
                    link: "/news/#contacts",
                },
                {
                    title: "Press Kit",
                    link: "/news/#press-kit",
                    isBig: true,
                },
            ],
        },
        {
            label: "News",
            subMenuItems: [
                {
                    title: "Partnerships",
                    link: "/news/partnerships",
                },
                {
                    title: "Announcements",
                    link: "/news/announcements/",
                },
                {
                    title: "Project Reports",
                    link: "/news/project-reports/",
                },
                {
                    title: "Security and Privacy",
                    link: "/news/security-privacy/",
                },
                {
                    title: "Events",
                    link: "/news/events/",
                },
                {
                    title: "Interviews",
                    link: "/news/interviews/",
                },
                {
                    title: "Product Releases & Upgrades",
                    link: "/news/product-releases-upgrades",
                    isBig: true,
                },
            ],
        },
        {
            label: "Stories",
            subMenuItems: [
                {
                    title: "Sidechain Innovations",
                    link: "/news/sidechain-innovations/",
                },
                {
                    title: "Developer Community",
                    link: "/news/developer-community/",
                },
                {
                    title: "Inclusion & Empowerment",
                    link: "/news/inclusion-empowerment/",
                },
                {
                    title: "Our People",
                    link: "/news/our-people/",
                },
                {
                    title: "Giving Back",
                    link: "/news/giving-back/",
                },
                {
                    title: "Community Contests",
                    link: "/news/community-contests/",
                },
                {
                    title: "Tutorials",
                    link: "/news/tutorials/",
                },
                {
                    title: "Our Brand Journey",
                    link: "/news/our-brand-journey/",
                },
            ],
        },
        {
            label: "中文",
            href: "/news/chinese/",
        },
    ],
    rightMenuItems: [
        {
            label: "Return to Horizen",
            href: "/",
        },
    ],
    logoLink: "/news",
    logo: <NewsroomLogo />,
}

export default NewsHeaderProps
