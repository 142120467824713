import React from "react"

interface BannerProps {
    imgUrl: string
    alt: string
    href: string
}

const Banner: React.FC<BannerProps> = ({ alt, href, imgUrl }) => {
    return (
        <section className="bg-white">
            <a href={href} rel="noopener noreferrer" target="_blank">
                <img
                    className="object-fill lg:object-cover w-[100vw] 2xl:max-w-screen-xl mx-auto"
                    src={imgUrl}
                    alt={alt}
                />
            </a>
        </section>
    )
}

export default Banner
