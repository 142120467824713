import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { QueryClient, QueryClientProvider } from "react-query"
import Footer from "../../../components/Footer"
import { Header, NewsHeaderProps } from "./Header"

const queryClient = new QueryClient()
interface NewsLayoutProps {
    children: React.ReactNode
}

const NewsLayout: React.FC<NewsLayoutProps> = ({ children }) => {
    useEffect(() => {
        window.dataLayer.push({
            event: "pageview",
        })

        if (typeof document !== "undefined") {
            document.getElementsByTagName("html")[0].style.scrollBehavior =
                "smooth"
        }
    }, [])
    return (
        <QueryClientProvider client={queryClient}>
            <div className="font-proxima" style={{ overflowAnchor: "none" }}>
                <Helmet>
                    <link
                        rel="preload"
                        as="style"
                        href="https://use.typekit.net/elr1akh.css"
                    />
                    <link
                        rel="stylesheet"
                        href="https://use.typekit.net/elr1akh.css"
                    />
                </Helmet>
                <Header
                    leftMenuItems={NewsHeaderProps.leftMenuItems}
                    rightMenuItems={NewsHeaderProps.rightMenuItems}
                    logoLink={NewsHeaderProps.logoLink}
                    logo={NewsHeaderProps.logo}
                />
                {children}
                <Footer />
            </div>
        </QueryClientProvider>
    )
}

export default NewsLayout
