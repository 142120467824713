/* eslint-disable react/no-danger */
import React, { useCallback, useEffect, useState } from "react"
import useEmblaCarousel from "embla-carousel-react"
import Autoplay from "embla-carousel-autoplay"
import { useQuery } from "react-query"
import Link from "../../../components/OldDesign/Link"
import ContentContainer from "../../../templates/ContentContainer"
import { WPMiddleware } from "./middlewares"
import { WPPostT } from "./types"
import Loading from "../../../components/Loading"

const autoplayPlugin = Autoplay({
    delay: 6000,
    stopOnInteraction: false,
})

interface DotBtnProps {
    selected: boolean
    slide: number
    onClick: () => void
}

const DotBtn: React.FC<DotBtnProps> = ({ selected, slide, onClick }) => {
    return (
        <button
            type="button"
            aria-label={`Go to slide ${slide}`}
            onClick={onClick}
            className={`w-[14px] h-[14px] rounded-full border border-[#7e8ea8] bg-white hover:bg-old-design-mid-blue transition-colors ${
                selected ? "!bg-old-design-blue" : ""
            }`}
        />
    )
}

interface SlideProps {
    post: WPPostT
}

const Slide: React.FC<SlideProps> = ({ post }) => {
    return (
        <div className="grow-0 shrink-0 justify-center basis-[100%]">
            <div className="grid lg:grid-cols-2 gap-6 lg:gap-12">
                <img
                    className="w-full h-full object-cover bg-old-design-dark-blue"
                    src={post.image}
                    alt={post.title}
                />

                <div className="flex flex-col justify-center">
                    <h2
                        className="font-proxima text-lg sm:text-3xl font-bold text-old-design-dark-blue"
                        dangerouslySetInnerHTML={{ __html: post.title }}
                    />
                    <p
                        className="font-proxima text-lg text-old-design-dark-blue mt-4 mb-4 overflow-hidden [display:-webkit-box] [-webkit-line-clamp:2] [-webkit-box-orient:vertical]"
                        dangerouslySetInnerHTML={{ __html: post.description }}
                    />
                    <Link href={post.link} newTab>
                        Read more
                    </Link>
                </div>
            </div>
        </div>
    )
}

const NewsHeroCarousel = () => {
    const { data, isSuccess } = useQuery(
        "news-featured",
        WPMiddleware.getFeaturedPosts
    )
    const [scrollSnaps, setScrollSnaps] = useState<number[]>([])
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [emblaRef, emblaApi] = useEmblaCarousel(
        {
            loop: true,
            align: "start",
        },
        [autoplayPlugin]
    )

    const onSelect = useCallback(() => {
        if (!emblaApi) return
        setSelectedIndex(emblaApi.selectedScrollSnap())
    }, [emblaApi, setSelectedIndex])

    const scrollTo = useCallback(
        (index) => emblaApi && emblaApi.scrollTo(index),
        [emblaApi]
    )

    useEffect(() => {
        if (!emblaApi) return
        setScrollSnaps(emblaApi.scrollSnapList())

        onSelect()
        emblaApi.on("select", onSelect)
    }, [emblaApi, setScrollSnaps, onSelect])

    return (
        <section className="bg-white py-16">
            <ContentContainer>
                {isSuccess ? (
                    <>
                        <div className="overflow-hidden" ref={emblaRef}>
                            <div className="flex gap-6">
                                {data.map((post) => (
                                    <Slide key={post.id} post={post} />
                                ))}
                            </div>
                        </div>

                        <div className="flex gap-3 justify-center mt-12">
                            {scrollSnaps.map((snap, index) => (
                                <DotBtn
                                    key={snap}
                                    slide={index}
                                    selected={index === selectedIndex}
                                    onClick={() => scrollTo(index)}
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </ContentContainer>
        </section>
    )
}

export default NewsHeroCarousel
