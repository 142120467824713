import React from "react"
import { useQuery } from "react-query"
import Loading from "../../../../components/Loading"
import Link from "../../../../components/OldDesign/Link"
import { WPMiddleware } from "../middlewares"
import Post from "../Post"

const PressReleases = () => {
    const { data, isSuccess } = useQuery(
        "news-press-releases",
        WPMiddleware.getPressReleases
    )

    return (
        <div id="press-releases">
            <h2 className="font-proxima text-3xl font-bold text-old-design-dark-blue">
                PRESS RELEASES
            </h2>
            {isSuccess ? (
                <>
                    <div className="mt-6 grid gap-8 lg:gap-4 auto-rows-fr">
                        {data.map((post) => (
                            <Post key={post.id} post={post} />
                        ))}
                    </div>
                    <div className="flex justify-end mt-6">
                        <Link href="/news/press-releases">View All</Link>
                    </div>
                </>
            ) : (
                <Loading />
            )}
        </div>
    )
}

export default PressReleases
